export const MenuList = [
  {
    title: "Favorites",
    url: "/favoriterecipes",
  },
  {
    title: "My Recipes",
    url: "/myrecipes",
  },
  {
    title: "Log out",
    url: "/logout",
  },
  {
    title: "Swipe",
    url: "/demo",
  },
];
