import React from 'react'
import Deck from '../swiper/Deck'

const Demo = () => {
    return (
        <div id="deckroot">
            <Deck />
        </div>
    )
};

export default Demo;
