export const MenuList2 = [
    {
      title: "Login",
      url: "/",
    },
    {
      title: "Register",
      url: "/register",
    },
  ];
  